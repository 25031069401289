import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108')
];

export const server_loads = [0,10,11,17,2,5,24,26,27,3];

export const dictionary = {
		"/(landings)/(home)": [~105],
		"/(iam)/auth/edu": [~97,[30]],
		"/(iam)/auth/invite": [~98],
		"/(iam)/auth/password": [~99],
		"/(iam)/auth/recover": [~100,[29]],
		"/(iam)/auth/signin": [~101,[29]],
		"/(iam)/auth/signup": [~102,[29]],
		"/(hub)/console": [50,[10]],
		"/(hub)/console/cms/[resource_id]/richtext": [51,[11]],
		"/(hub)/console/cms/[resource_id]/text": [~52,[12]],
		"/(hub)/console/codes": [~53,[10]],
		"/(hub)/console/codes/[id]": [~54,[13]],
		"/(hub)/console/crm/feedback": [~55,[10]],
		"/(hub)/console/crm/partnership": [~56,[10]],
		"/(hub)/console/crm/subscription": [~57,[10]],
		"/(hub)/console/feed": [~58,[10]],
		"/(hub)/console/feed/[post_id]/edit": [~59,[10]],
		"/(hub)/console/organizations": [~60,[10]],
		"/(hub)/console/organizations/[id]": [~61,[14]],
		"/(hub)/console/organizations/[id]/groups": [~62,[14]],
		"/(hub)/console/organizations/[id]/groups/[group_id]": [~63,[15]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/roles": [~64,[15]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/terms": [~65,[15]],
		"/(hub)/console/organizations/[id]/roles": [~66,[14]],
		"/(hub)/console/terms": [~67,[10]],
		"/(hub)/console/terms/[id]": [~68,[16]],
		"/(hub)/console/terms/[id]/blueprints": [~69,[16]],
		"/(hub)/console/terms/[id]/changes": [~70,[16]],
		"/(hub)/console/users": [~71,[10]],
		"/(hub)/dev": [72,[17],[18]],
		"/(hub)/dev/examples": [~73,[19]],
		"/(hub)/dev/examples/await": [74,[19]],
		"/(hub)/dev/examples/scheduler": [75,[19]],
		"/(hub)/dev/examples/seaweedfs": [76,[19]],
		"/(hub)/dev/examples/xapi": [77,[19]],
		"/(hub)/dev/examples/yjs": [78,[19]],
		"/(hub)/dev/landing": [79,[20]],
		"/(hub)/dev/playground": [~80,[21]],
		"/(hub)/dev/playground/buttons": [81,[21]],
		"/(hub)/dev/playground/confirm": [82,[21]],
		"/(hub)/dev/playground/dates": [83,[21]],
		"/(hub)/dev/playground/inputs": [~84,[21]],
		"/(hub)/dev/playground/modal": [85,[21]],
		"/(hub)/dev/playground/popover": [86,[21]],
		"/(hub)/dev/playground/switch": [87,[21]],
		"/(hub)/dev/playground/tabs copy": [89,[21]],
		"/(hub)/dev/playground/tabs": [88,[21]],
		"/(hub)/dev/playground/toasts": [90,[21]],
		"/(landings)/energy": [106,[32]],
		"/(education)/events": [37,[4]],
		"/(landings)/faststart": [107],
		"/(education)/feed": [~38,[2]],
		"/(education)/groups": [~39,[2]],
		"/(education)/groups/[id]": [~40,[5]],
		"/(education)/groups/[id]/terms": [~41,[5]],
		"/(education)/home": [~42,[6]],
		"/legal/user-agreement": [108,[33]],
		"/(hub)/lms": [91,[22],[23]],
		"/(hub)/lms/[type=blueprint_type]": [~92,[22],[23]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/content": [94,[24,26,27],[25]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/materials": [95,[24,26],[25]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]": [~93,[24,26],[25]],
		"/(hub)/mentorship": [96,[28]],
		"/(education)/org/contest": [47,[8]],
		"/(education)/org/[org_id]/teams": [~43,[7]],
		"/(education)/org/[org_id]/teams/personal": [~45,[7]],
		"/(education)/org/[org_id]/teams/team": [46,[7]],
		"/(education)/org/[org_id]/teams/[group_id]": [~44,[7]],
		"/(education)/rewards": [~48,[2]],
		"/(education)/rewards/[reward_id]": [~49,[2],[,9]],
		"/(iam)/user": [~103,[31]],
		"/(iam)/user/security": [~104,[31]],
		"/(education)/[type=term_type]": [~34,[2]],
		"/(education)/[type=term_type]/[slug]": [~35,[3]],
		"/(education)/[type=term_type]/[slug]/[blueprint_id]": [~36,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';